import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_SENTRY_DSN, PUBLIC_ENVIRONMENT } from '$env/static/public';

Sentry.init({
  tracesSampleRate: PUBLIC_ENVIRONMENT === 'prod' ? 0.1 : 0,
  profilesSampleRate: PUBLIC_ENVIRONMENT === 'prod' ? 0.1 : 0,
  dsn: PUBLIC_SENTRY_DSN,
  environment: PUBLIC_ENVIRONMENT
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
