import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(app)/campaigns": [6,[2]],
		"/(app)/campaigns/[uuid]/configure-your-site": [~7,[2,3]],
		"/(app)/campaigns/[uuid]/confirm-pricing-and-shipping": [~8,[2,3]],
		"/(app)/campaigns/[uuid]/products/collections": [~9,[2,3]],
		"/(app)/campaigns/[uuid]/your-products-have-been-added": [10,[2,3]],
		"/(auth)/login-token": [~21,[5]],
		"/(auth)/login": [~20,[5]],
		"/(app)/logout": [11,[2]],
		"/(auth)/register": [~22,[5]],
		"/sentry-example": [23],
		"/(app)/sites": [12,[2]],
		"/(app)/vendor-stores": [~13,[2]],
		"/(app)/vendor-stores/[uuid]/campaigns": [~14,[2,4]],
		"/(app)/vendor-stores/[uuid]/campaigns/new/configure-your-campaign": [17],
		"/(app)/vendor-stores/[uuid]/campaigns/new/confirm-pricing-and-shipping": [18,[2,4]],
		"/(app)/vendor-stores/[uuid]/campaigns/new/name-your-campaign": [19],
		"/(app)/vendor-stores/[uuid]/campaigns/[uuid]/edit/configure-your-site": [15,[2,4]],
		"/(app)/vendor-stores/[uuid]/campaigns/[uuid]/edit/confirm-pricing-and-shipping": [16,[2,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';